import { useQuery } from "@apollo/client";
import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import { Badge } from "../../components/Badge.tsx";
import { BillingAccountBlock } from "../accounts/BillingAccountBlock.tsx";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import { BooleanComponentFilter, NumberRangeFilter, TextFilter, UnixDateRangeFilter } from "../filters.tsx";
import { gql } from "../graphql.gen/gql.ts";
import { BucketOrderFields, OrderDirection, type GetBucketListQuery } from "../graphql.gen/graphql.ts";
import { extractData } from "../graphql/extractData.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { baIdField, numberField, sizeBytesGbField, userIdField } from "../table_fields/standardFields.tsx";

const GET_BUCKET_LIST = gql(/* GraphQL */ `
    query GetBucketList(
        $search: String
        $limit: Int
        $page: Int
        $orderField: BucketOrderFields
        $orderDir: OrderDirection
        $filters: [BucketFilter!]
    ) {
        admin_bucket_list(
            search: $search
            limit: $limit
            page: $page
            orderField: $orderField
            orderDir: $orderDir
            filters: $filters
        ) {
            items {
                name
                owner
                user_id
                size_bytes
                billing_account_id
                num_objects
                created_at
                is_suspended
                billing_account {
                    ...BillingAccountBlock
                }
            }
            paging {
                total
            }
        }
    }
`);

export type GQBucketItem = NonNullable<GetBucketListQuery["admin_bucket_list"]["items"]>[number];

function SuspendedBadge({ value }: { value: boolean }) {
    return <Badge color={value ? "error" : "success"}>{value ? "Suspended" : "Available"}</Badge>;
}

const bucketFields: GqlFieldsOf<GQBucketItem, BucketOrderFields> = [
    gf({
        ...userIdField,
        get: (a) => a.user_id,
        order: BucketOrderFields.UserId,
    }),
    gf({
        id: "owner",
        title: "Ceph Storage Username",
        get: (a) => a.owner,
        copyable: true,
        hidden: true,
        order: BucketOrderFields.Owner,
        filter: TextFilter,
    }),
    gf({
        ...baIdField,
        get: (a) => a.billing_account_id,
        order: BucketOrderFields.BillingAccountId,
    }),
    gf({
        id: "billing_account",
        title: "Billing Account",
        get: (a) => a.billing_account,
        render: BillingAccountBlock,
    }),
    gf({
        id: "created",
        title: "Created At",
        get: (a) => (a.created_at ? apiUnixToDate(a.created_at as number) : undefined),
        order: BucketOrderFields.CreatedAt,
        filter: UnixDateRangeFilter,
    }),
    gf({
        id: "name",
        title: "Bucket Name",
        get: (a) => a.name,
        copyable: true,
        order: BucketOrderFields.Name,
    }),
    gf({
        ...sizeBytesGbField,
        id: "size_bytes",
        title: "Size",
        get: (a) => a.size_bytes,
        order: BucketOrderFields.SizeBytes,
        filter: NumberRangeFilter,
    }),
    gf({
        ...numberField,
        id: "num_objects",
        title: "File\nCount",
        get: (a) => a.num_objects,
        order: BucketOrderFields.NumObjects,
        filter: NumberRangeFilter,
    }),
    gf({
        id: "is_suspended",
        title: "Status",
        get: (a) => a.is_suspended,
        render: SuspendedBadge,
        order: BucketOrderFields.IsSuspended,
        filter: () => <BooleanComponentFilter component={SuspendedBadge} />,
    }),
];

export function BucketsTable() {
    return (
        <GraphqlTable
            title="Object Storage"
            fields={bucketFields}
            defaults={{ orderField: BucketOrderFields.CreatedAt, orderDir: OrderDirection.Desc }}
            getId={(item) => item.name}
            useQuery={(variables) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const q = useQuery(GET_BUCKET_LIST, { variables });
                return extractData(q, (d) => d.admin_bucket_list);
            }}
        />
    );
}
